<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <h3>
            {{ $t('labels.files') }}
          </h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs">
            <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.members') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/members/downloads" class="breadcrumbs-link"> {{ $t('labels.files') }}</router-link>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <div class="col-3">
          <div class="widget">
            <div class="widget-header">
              <div class="widget-header-label"><h3>{{ $t('labels.printing-file') }}</h3></div>
              <div class="widget-header-toolbar">
                <i class="far fa-file-excel text-muted"></i>
              </div>
            </div>
            <div class="widget-body no-padding">
              <div class="row bb-1">
                <div class="col-12">
                  <div class="pt-3 pb-3" style="padding-left: 25px; padding-right: 25px;">
                    {{ $t('labels.address-list-for-newspaper') }}
                  </div>
                </div>
              </div>
              <div class="pt-3 pb-3" style="padding-left: 25px;">
                <div class="row">
                  <div class="col-5 fw-500">
                    {{ $t('labels.format') }}
                  </div>
                  <div class="col-5">
                    MS Excel
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-5 fw-500">
                    {{ $t('labels.size') }}
                  </div>
                  <div class="col-5">
                    ~ 250 kb
                  </div>
                </div>
              </div>
            </div>
            <div class="widget-footer pt-3 pb-3">
              <button class="btn btn-success" @click="downloadPrintServiceFile()"
              ><i class="fas fa-download mr-2"></i> {{ $t('labels.download') }}</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import _ from 'lodash';
import BojAPI from '@/api/boj';

export default {
  components: {
    LayoutMain,
  },
  data() {
    return {
      endpoint: process.env.VUE_APP_API_LINK,
      getSortBy: 'id',
      isLoading: false,
    };
  },
  methods: {

        /**
         *
         * @param url
         * @param format
         */
        downloadPrintServiceFile() {
            BojAPI.getPrintServiceFile()
            .then((response) => {
              this.fileDownload(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$notify({
                        group: 'foo',
                        text: this.$t('labels.no-permission'),
                        type: 'warning',
                        duration: 3000,
                    });
                }
            });
        },

        /**
         * Force file download using result from Axios.
         */
        fileDownload(response, filename, fileurl) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            link.setAttribute('download', 'Adresslista-tryckeri.xlsx');

            document.body.appendChild(link);
            link.click();
            this.isDownloading = false;
        },

  }
};
</script>
